import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginAuth } from "./Api";

// Define interfaces for state
interface Auth {
  // Define properties for Auth object
}

interface InitialState {
  Auths: Auth[]; // Assuming Auths is an array of Auth objects
  Auth: Auth[]; // Assuming Auth is an array of Auth objects
  Active_Role: string;
  page: number;
  loading: boolean;
  error: any;
}

// Define type for thunk function payload
type ThunkAPIPayload = {
  rejectValue: {
    message: string;
  };
};

// Define type for the thunk function itself
type AsyncThunkConfig = {
  state: InitialState;
  rejectValue: {
    message: string;
  };
};

// Define type for the API function
type ApiFunction = (data: any) => Promise<any>; // Adjust 'any' to actual API response type

// Define type for the payload received from API
type ApiResponse = {
  data: any; // Adjust 'any' to actual API response type
};

// Define type for action payloads
type SetRolePayload = string; // Assuming payload for setRole action is string

// Define type for action creators
 

// Define initial state
const initialState: InitialState = {
  Auths: [],
  Auth: [],
  Active_Role: "",
  page: 1,
  loading: false,
  error: '',
};

// Define async thunk function
const AsyncFunctionThunk = (name: string, apiFunction: ApiFunction) => {
  return createAsyncThunk<any, any, AsyncThunkConfig>(`Auth/${name}`, async (data, { rejectWithValue }) => {
    try {
      const response = await apiFunction(data);
      
      return response.data;
    } catch (error:any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: error.message });
    }
  });
};

// Define async thunk action
export const LoginAuths = AsyncFunctionThunk('LoginAuth', LoginAuth);

// Define AuthSlice
const AuthSlice = createSlice({
  name: "AuthSlice",
  initialState,
  reducers: {
     
    ErrorClear: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginAuths.fulfilled, (state, action) => {
        state.Auth = action.payload;
        state.loading = false;
      })
      .addCase(LoginAuths.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAuths.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

 

// Export reducer
export default AuthSlice.reducer;
