import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import { BASE_URL } from '../../../API';
import { motion } from 'framer-motion';
import { createUseStyles } from 'react-jss';
// @ts-ignore
import { isWebpSupported } from 'react-image-webp/dist/utils';
import Item1 from '../../../assets/img/animation-items/item.png';
import Item1Webp from '../../../assets/img/animation-items/item.webp';
import Item2 from '../../../assets/img/animation-items/item2.png';
import Item2Webp from '../../../assets/img/animation-items/item2.webp';
import Item3 from '../../../assets/img/animation-items/item3.png';
import Item3Webp from '../../../assets/img/animation-items/item3.webp';
import Item4 from '../../../assets/img/animation-items/item4.png';
import Item4Webp from '../../../assets/img/animation-items/item4.webp';
import Item5 from '../../../assets/img/animation-items/item5.png';
import Item5Webp from '../../../assets/img/animation-items/item5.webp';
import Item6 from '../../../assets/img/animation-items/item6.png';
import Item6Webp from '../../../assets/img/animation-items/item6.webp';
import Item7 from '../../../assets/img/animation-items/item7.png';
import Item7Webp from '../../../assets/img/animation-items/item7.webp';
import Item8 from '../../../assets/img/animation-items/item8.png';
import Item8Webp from '../../../assets/img/animation-items/item8.webp';
import Item9 from '../../../assets/img/animation-items/item9.png';
import Item9Webp from '../../../assets/img/animation-items/item9.webp';
import Item10 from '../../../assets/img/animation-items/item10.png';
import Item10Webp from '../../../assets/img/animation-items/item10.webp';
import Item11 from '../../../assets/img/animation-items/item11.png';
import Item11Webp from '../../../assets/img/animation-items/item11.webp';
import Item12 from '../../../assets/img/animation-items/item12.png';
import Item12Webp from '../../../assets/img/animation-items/item12.webp';
import Item13 from '../../../assets/img/animation-items/item13.png';
import Item13Webp from '../../../assets/img/animation-items/item13.webp';
import Item14 from '../../../assets/img/animation-items/item14.png';
import Item14Webp from '../../../assets/img/animation-items/item14.webp';
import { useMeasure } from 'react-use';
import Richie from '../../../assets/img/richie.png';
import RichieWebp from '../../../assets/img/richie.webp';
import BootstrapLogo from '../../../assets/img/bootstrap.svg';
import StorybookLogo from '../../../assets/img/storybbok.png';
import StorybookLogoWebp from '../../../assets/img/storybbok.webp';
import FormikLogo from '../../../assets/img/formik.jpeg';
import FormikLogoWebp from '../../../assets/img/formik.webp';
import ApexLogo from '../../../assets/img/apexcharts.png';
import ApexLogoWebp from '../../../assets/img/apexcharts.webp';
import axios from 'axios';
import { LoginAuths } from '../../../store/Auth/Slice';
import { useDispatch } from 'react-redux';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';

const useStyles = createUseStyles({
	// stylelint-disable
	intro: {
		zIndex: 200,
		overflow: 'hidden',
	},

	introImageWrapper: {
		zIndex: 1,
	},

	introImage: {
		backgroundImage: `url(${isWebpSupported() ? RichieWebp : Richie})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		height: '100%',

		'@media (min-width: 992px)': {
			backgroundPosition: 'center right',
			transform: 'translateX(10vw)',
		},
	},

	introContentWrapper: {
		zIndex: 2,
	},

	animation: {
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: 'flex',
		overflow: 'hidden',
		width: '100%',
		height: '100vh',
		alignItems: 'flex-start',
		justifyContent: 'center',
	},

	item: {
		position: 'relative',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		transformStyle: 'preserve-3d',
		willChange: 'transform',
	},

	item1: {
		top: '10%',
		width: 20,
		height: 20,
		backgroundImage: `url(${isWebpSupported() ? Item1Webp : Item1})`,
	},

	item2: {
		top: '15%',
		width: 55,
		height: 55,
		backgroundImage: `url(${isWebpSupported() ? Item2Webp : Item2})`,
	},

	item3: {
		top: '37%',
		left: '-30%',
		width: 45,
		height: 45,
		backgroundImage: `url(${isWebpSupported() ? Item3Webp : Item3})`,
	},

	item4: {
		width: 15,
		height: 15,
		margin: 'auto 10px',
		backgroundImage: `url(${isWebpSupported() ? Item4Webp : Item4})`,
	},

	item5: {
		top: '78%',
		right: '24%',
		bottom: 'auto',
		left: 'auto',
		width: 12,
		height: 12,
		backgroundImage: `url(${isWebpSupported() ? Item5Webp : Item5})`,
	},

	item6: {
		top: '53%',
		right: '20%',
		bottom: 'auto',
		left: 'auto',
		width: 10,
		height: 10,
		backgroundImage: `url(${isWebpSupported() ? Item6Webp : Item6})`,
	},

	item7: {
		top: '60%',
		right: '0%',
		bottom: 'auto',
		left: '0%',
		width: 25,
		height: 25,
		backgroundImage: `url(${isWebpSupported() ? Item7Webp : Item7})`,
	},

	item8: {
		position: 'absolute',
		top: 'auto',
		right: '8%',
		bottom: '14%',
		width: 44,
		height: 44,
		backgroundImage: `url(${isWebpSupported() ? Item8Webp : Item8})`,
	},

	item9: {
		top: '32%',
		bottom: 'auto',
		left: '16%',
		width: 10,
		height: 10,
		backgroundImage: `url(${isWebpSupported() ? Item9Webp : Item9})`,
	},

	item10: {
		top: '32%',
		right: '-35%',
		width: 32,
		height: 32,
		backgroundImage: `url(${isWebpSupported() ? Item10Webp : Item10})`,
	},

	item11: {
		top: '71%',
		right: 'auto',
		bottom: 'auto',
		left: '38%',
		width: 20,
		height: 20,
		backgroundImage: `url(${isWebpSupported() ? Item11Webp : Item11})`,
	},

	item12: {
		top: '73%',
		right: 'auto',
		bottom: 'auto',
		left: '-44%',
		width: 18,
		height: 18,
		backgroundImage: `url(${isWebpSupported() ? Item12Webp : Item12})`,
	},

	item13: {
		top: '56%',
		right: 'auto',
		bottom: 'auto',
		left: '30%',
		width: 12,
		height: 12,
		backgroundImage: `url(${isWebpSupported() ? Item13Webp : Item13})`,
	},

	item14: {
		top: '93%',
		right: '-22%',
		bottom: 'auto',
		left: '0%',
		width: 18,
		height: 18,
		backgroundImage: `url(${isWebpSupported() ? Item14Webp : Item14})`,
	},

	logoWrapper: {
		height: 300,

		'@media (minwidth: 992px)': {
			height: 450,
		},
	},

	bootstrapLogo: {
		height: '100%',
		backgroundImage: `url(${BootstrapLogo})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	storybookLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? StorybookLogoWebp : StorybookLogo})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	formikLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? FormikLogoWebp : FormikLogo})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},

	apexLogo: {
		height: '100%',
		backgroundImage: `url(${isWebpSupported() ? ApexLogoWebp : ApexLogo})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
	},
	// stylelint-enable
});



interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-2'>Welcome</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setUser,setRole } = useContext(AuthContext);
const dispatch = useDispatch()
	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const classes = useStyles();
	const [ref, { height }] = useMeasure<HTMLDivElement>();
	const navigate = useNavigate();
	const [otpVerify,setOtpVerify]=useState<any>(false)
	const handleAdmin = useCallback(() => navigate('/dashboard'), [navigate]);
const [otptoken,setOtptoken]=useState<any>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			name:'',
			phone: '',
			otp:'',
			password:''
		},
		validate: (values) => {
			const errors: { email?: string; password?: string } = {};

			if (!values.email) {
				errors.email = 'Required';
			}

			if (!values.phone) {
				errors.password = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			setIsLoading(true);
			if (values) {

 


				handleOnClick();
 
			} else {
				formik.setFieldError('loginPassword', 'email and password do not match.');
				setIsLoading(false);
			}
		},
	});




const loginWithPass = async()=>{
	setIsLoading(true)
	try {
	const res =	await dispatch(LoginAuths({loginData:{ password:formik.values.password ,email:formik.values.email, phone:formik.values.phone }} )as any)
	// const response :any = await axios.post(`https://do.meander.software:1000/api/users/login`, { password:formik.values.password ,email:formik.values.email, phone:formik.values.phone });
	const data = await res

		if (data?.payload?.isSuccess) {

			localStorage.setItem('login', JSON.stringify(data?.payload));
			localStorage.setItem('facit_authUsername',data?.payload?.data?.name);
			localStorage.setItem('authRole',data?.payload?.data?.roles);
			localStorage.setItem('logintoken',JSON.stringify(data?.payload?.data?.token) )
			 
			navigate('/')

			setOtpVerify(true)
			setIsLoading(false);
			 
		 
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>{data?.payload?.data?.name} user login Successfully</span>
				</span>,
				'login has been updated successfully',
			);
		 
			return data.data;
		} else {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span>Wrong Credentials</span>
				</span>,
				'Wrong Credentials, please enter valid name and password',
			);
			alert('');
			setIsLoading(false);

			throw new Error('wrong');
		}
		// if (data ) {
		// 	if (setSingUpStatus) {
		// 		setSingUpStatus(!singUpStatus)
		// 	}
			 
		 
		// 	return data.data;
		// } else {
		// 	alert('Wrong Credentials, please enter valid name and password');
		// 	throw new Error('wrong');
		// }
	} catch (err) {
		throw err;
	}


}
const handleKeyDown = (e:any) => {
 
	if (e.key === 'Enter') {
		loginWithPass();
	}
};
	const handleOnClick =   async() =>{ 
		try {
			const options = {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ name:formik.values.name ,email:formik.values.email, phone:formik.values.phone }),
			};
			const response = await axios.post(`https://do.meander.software:5000/api/client/create`, { name:formik.values.name ,email:formik.values.email, phone:formik.values.phone,password :formik.values.password });
			const data = await response ;
			 
			if (data ) {
				if (setSingUpStatus) {
					setSingUpStatus(!singUpStatus)
				}
				 
			 
				return data.data;
			} else {
				alert('Wrong Credentials, please enter valid name and password');
				throw new Error('wrong');
			}
		} catch (err) {
			throw err;
		}
	}  ;
 

	return (
	 
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-dark': singUpStatus, 'bg-light': !singUpStatus })}>
			<Page className='p-0' >
				<div  className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-2'>
									<Link
										to=''
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={110} height={90} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-12'>
										<FormGroup
											id='name'
											isFloating
											label='Enter Your name'>
											<Input
												autoComplete='name'
												value={formik.values.name}
												isTouched={formik.touched.name}
												invalidFeedback={formik.errors.name}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
									</div>
											<div className='col-12'>
												<FormGroup
													id='email'
													isFloating
													label='Enter Your email'>
													<Input
														autoComplete='email'
														value={formik.values.email}
														isTouched={formik.touched.email}
														invalidFeedback={formik.errors.email}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
										
											
											<div className='col-12'>
												<FormGroup
													id='phone'
													isFloating
													label='Enter your phone'>
													<Input
														autoComplete='phone'
														value={formik.values.phone}
														isTouched={formik.touched.phone}
														invalidFeedback={formik.errors.phone}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label='Enter your password'>
													<Input
														autoComplete='password'
														value={formik.values.password}
														isTouched={formik.touched.password}
														invalidFeedback={formik.errors.password}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
												 
													color='info'
													className='w-100 py-3'
													onClick={formik.handleSubmit}>
													Sign Up
												</Button>
											</div>
										</>
									) : (

										
										<>






										{/* {
											!otpVerify ?( */}
												<>
<div className='col-12'>
												<FormGroup
													id='email'
													isFloating
													label='Your email'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='email'
														value={formik.values.email}
														isTouched={formik.touched.email}
														invalidFeedback={formik.errors.email}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>


											<div className='col-12'>
												<FormGroup
													id='password'
													isFloating
													label='Your Password'>
													<Input
													type='password'
														autoComplete='password'
														value={formik.values.password}
														isTouched={formik.touched.password}
														 
														onKeyDown={handleKeyDown}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													onKeyDown={handleKeyDown}
													onClick={loginWithPass}>

{isLoading ?<Spinner
  color="primary"
  isSmall
>
  Loading...
</Spinner>:
													"Login"}
												</Button>
											</div>
											{/* <div className='text-center'>
												<h4 style={{color:'gray'}}>OR</h4>
											</div>
											<div className='col-12'>
												<FormGroup
													id='phone'
													isFloating
													label='Phone'>
													<Input
														type='text'
														autoComplete='current-phone'
														value={formik.values.phone}
														isTouched={formik.touched.phone}
														invalidFeedback={formik.errors.phone}
													 
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
													/>
												</FormGroup>
											</div>
											<div className='col-12'>
												<Button
													color='warning'
													className='w-100 py-3'
													onClick={loginHandle}>
													Send Otp
												</Button>
											</div> */}
											</>
											{/* ):(
												<>
												<div className='col-12'>
																								<FormGroup
																									id='otp'
																									isFloating
																									label='OTP'
																									className={classNames({
																										'd-none': signInPassword,
																									})}>
																									<Input
																										autoComplete='otp'
																										value={formik.values.otp}
																										isTouched={formik.touched.otp}
																										invalidFeedback={formik.errors.otp}
																										isValid={formik.isValid}
																										onChange={formik.handleChange}
																										onBlur={formik.handleBlur}
																										onFocus={() => {
																											formik.setErrors({});
																										}}
																									/>
																								</FormGroup>
																							</div>
																							 
																							<div className='col-12'>
																								<Button
																									color='warning'
																									className='w-100 py-3'
																									onClick={verifyHandle}>
																									Login
																								</Button>
																							</div>
																							</>
											)
										} */}
											
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='#'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='#'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
					<div className={classes.animation}>
						<motion.div
							animate={{
								x: [height * -0.3, height * -0.35, height * -0.3],
								y: [height * 0.2, height * 0.25, height * 0.2],
							}}
							transition={{
								duration: 15,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item1)}
						/>
						<motion.div
							animate={{
								x: [0, -4, 0],
								rotate: [0, 180, 360],
							}}
							transition={{
								duration: 25,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item2)}
						/>
						<motion.div
							animate={{
								x: [-48, 0, -48],
								y: [-170, 0, -170],
								rotate: [0, 180, 360],
							}}
							transition={{
								duration: 32,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item3)}
						/>
						<motion.div
							animate={{
								y: [0, 150, 0],
							}}
							transition={{
								duration: 18,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item4)}
						/>
						<motion.div
							animate={{
								x: [0, 200, 0],
								y: [0, 200, 0],
							}}
							transition={{
								duration: 46,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item5)}
						/>
						<motion.div
							animate={{
								x: [0, -150, 0],
							}}
							transition={{
								duration: 22,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item6)}
						/>
						<motion.div
							animate={{
								x: [0, -300, 0],
								y: [0, -100, 0],
							}}
							transition={{
								duration: 22,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item7)}
						/>
						<motion.div
							animate={{
								x: [0, -200, 0],
								y: [0, -200, 0],
								rotate: [0, 180, 360],
							}}
							transition={{
								duration: 38,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item8)}
						/>
						<motion.div
							animate={{
								x: [0, 120, 0],
								y: [0, 120, 0],
								rotate: [0, 180, 360],
							}}
							transition={{
								duration: 54,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item9)}
						/>
						<motion.div
							animate={{
								x: [50, 50, 50],
								y: [0, 140, 0],
								rotate: [0, 180, 360],
							}}
							transition={{
								duration: 15,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item10)}
						/>
						<motion.div
							animate={{
								x: [0, -120, 0],
								y: [0, -120, 0],
							}}
							transition={{
								duration: 36,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item11)}
						/>
						<motion.div
							animate={{
								x: [0, 100, 0],
								y: [0, -100, 0],
							}}
							transition={{
								duration: 34,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item12)}
						/>
						<motion.div
							animate={{
								x: [0, -200, 0],
								y: [0, -50, 0],
							}}
							transition={{
								duration: 34,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item13)}
						/>
						<motion.div
							animate={{
								x: [0, 200, 0],
								y: [0, -200, 0],
							}}
							transition={{
								duration: 34,
								ease: 'linear',
								times: [0, 0.5, 1],
								repeat: Infinity,
								repeatType: 'loop',
							}}
							className={classNames(classes.item, classes.item14)}
						/>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
