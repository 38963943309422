import React, { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getUserDataWithUsername, IUserProps } from '../common/data/userDummyData';

export interface IAuthContextProps {
	loginData:any;
	user: string;
	role: string;
	setUser?(...args: unknown[]): unknown;
	setRole?(...args: unknown[]): unknown;
	userData: Partial<IUserProps>;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
	children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState<string>(localStorage.getItem('facit_authUsername') || '');
	const [role, setRole] = useState<string>(localStorage.getItem('authRole') || 'user');
	const [userData, setUserData] = useState<Partial<IUserProps>>({});

	const loginData = JSON?.parse(localStorage?.getItem('login') || '{}');
	useEffect(() => {
		localStorage.setItem('facit_authUsername', user);
		// localStorage.setItem('authRole', role);
	}, [user]);

	useEffect(() => {
		if (user !== '') {
			setUserData(getUserDataWithUsername(user));
		} else {
			setUserData({});
		}
	}, [user]);

	const value = useMemo(
		() => ({
			user,
			loginData,
			setUser,
			role,
			setRole,
			userData,
		}),
		[user,role,loginData, userData],
	);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
