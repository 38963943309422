export const BASE_URL:string = 'https://backend.meander.one:7792';
// export const DoMeanderSoftware:string = 'https://do.meander.software:1000/api/';   // for staging stage api
export const DoMeanderSoftware:string = 'https://do.meander.software:5000/api/';   
export const getToken= ()=>{


   const token= JSON.parse(localStorage.getItem('logintoken') as any)

  return token
}


 export const TagIDs:any={
  variations:'667e85739f0dbb33e91cf832',
  subCategory:'667e852d9f0dbb33e91cf82e',
  method:'667fbd4729b60ae6a5616206',
  openAIproment:'668e43179b03001ac3621784'
}