import axios from "axios"
import { BASE_URL } from "../../API"

  

export const TagsCateGetAll = async(item:any)=>{
    const options={
        method:'GET',
        headers:{
            'content-Type':'application/json'
        }
    }
    const response =await fetch(`${BASE_URL}/api/tagCategories/gettag_categories?pageNo=1&pageSize=100`,options)
    const data = await response.json();
	return data;
}

export const TagsGetByTagCategoryId = async(data:any)=>{
    const {tagid}=data
    const response =await axios.get(`${BASE_URL}/api/tags/getTagByTag_categoriesId/${tagid}`)
	return response.data;
}
 
 