import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	deleteCategory,
	editCategorys,
	getCategorys,
	getCategorysById,
	postCategory,
	SearchCategory,
} from './CategoryApi';

export interface CategoryState<T> {
	items: T[];
	loading: boolean;
	current: T | null;
}
export interface ICategory {
	pageNo: number;
	PAGE_SIZE: number;
	token: string;
	body: any;
	CategoryId: any;
	value: any;
	_id: any;
}

const initialState: CategoryState<ICategory> = {
	items: [],
	current: null,
	loading: false,
};

//* Get Category //
export const getCategory = createAsyncThunk(
	'Category/getCategorys',
	async function ({ pageNo, PAGE_SIZE }: ICategory) {
		// const data1 = JSON.parse(window.localStorage.getItem('login') as any);
		// const token = data1.data.token;
		const response = await getCategorys();
		//   return response;

		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}
	},
);

//* Get Category //
export const SearchCategorys = createAsyncThunk(
	'Category/searchCategorys',
	async function ({ pageNo, PAGE_SIZE, value }: ICategory) {
		const data1 = JSON.parse(window.localStorage.getItem('login') as any);
		const token = data1.data.token;
		console.log(token, 'token');
		const response = await SearchCategory(token, pageNo, PAGE_SIZE, value);
		//   return response;
		console.log('res', response);

		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}
	},
);

//* Get Category By Id //
export const getCategoryById = createAsyncThunk(
	'Category/getCategorysById',
	async function ({ CategoryId }: Partial<ICategory>) {
		const data1 = JSON.parse(window.localStorage.getItem('login') as any);
		const token = data1.data.token;
		console.log(token, 'token');
		const response = await getCategorysById(token, CategoryId);
		//   return response;
		console.log('res', response);

		if (response.statusCode === 200) {
			return response.data[0];
		} else {
			throw response;
		}
	},
);

//* edit Category//
export const editCategory = createAsyncThunk('Category/editCategorys', async function (body: any) {
	const data1 = JSON.parse(localStorage.getItem('login') as any);

	const token = data1.data.token;

	const response = await editCategorys(body, token, body.id);
	//   return response;
	console.log('resmklzkxmc', response);
	if (response.statusCode === 200) {
		return response.data;
	} else {
		throw response;
	}
});
//* Add Category//

export const postCategorys = createAsyncThunk('Category/postCategory', async function (body:any) {
	// const data1 = JSON.parse(localStorage.getItem("login"));
	// const token = data1.data.token;
	const response = await postCategory(body);

	if (response.statusCode === 200) {
		return response.data;
	} else {
		throw response;
	}
});

// Delete Category//
export const deleteCategorys = createAsyncThunk('Category/deleteCategory', async function (body: any) {
	const data1 = JSON.parse(localStorage.getItem('login') as string);
	const token ='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NTE4MTJiZDBmODk1ZmI0OThiNjIyZTkiLCJpYXQiOjE3MDE4NTkwMzAsImV4cCI6MTcwMTg5ODYzMH0.WjuF0lbZTJ14icMEov1bp-PNoBJ7kMvzylkgToFGVq8';
	console.log(token,'=======>token')
	const response = await deleteCategory(body, token);
	// const response = await postCategory(body)
	console.log('okokokokoko', response);
	if (response.statusCode === 200) {
		return response.data;
	} else {
		throw response;
	}
});

const categories = createSlice({
	name: 'categories',
	initialState: initialState,
	reducers: {},
	extraReducers: {
		[getCategory.pending.type]: (state: CategoryState<any>) => {
			state.loading = true;
		},
		[getCategory.fulfilled.type]: (state, action) => {
			state.items = action.payload;
		},
		[getCategory.rejected.type]: (state) => {
			state.loading = false;
		},
		[getCategoryById.pending.type]: (state: CategoryState<any>) => {
			state.loading = true;
		},
		[getCategoryById.fulfilled.type]: (state, action) => {
			state.loading = false;
			state.current = action.payload;
		},
		[getCategoryById.rejected.type]: (state) => {
			state.loading = false;
		},
		[SearchCategorys.pending.type]: (state: CategoryState<any>) => {
			state.loading = true;
		},
		[SearchCategorys.fulfilled.type]: (state, action) => {
			state.items = action.payload;
		},
		[SearchCategorys.rejected.type]: (state) => {
			state.loading = false;
		},
		// [postCategorys.fulfilled.type]: (state, action) => {
		// 	state.items.push(action.payload);
		// 	state.loading = false;
		// },
		// [postCategorys.pending.type]: (state) => {
		// 	state.loading = true;
		// },

		// [postCategorys.rejected.type]: (state) => {
		// 	state.loading = false;
		// },
		[deleteCategorys.fulfilled.type]: (state, action) => {
			state.items.filter((items) => items._id !== action.payload);
		},
		[deleteCategorys.pending.type]: (state) => {
			state.loading = true;
		},

		[deleteCategorys.rejected.type]: (state) => {
			state.loading = false;
		},
		// [editCategory.fulfilled.type]: (state, action) => {
		// 	state.loading = false;
		// },
		// [editCategory.rejected.type]: (state, action) => {
		// 	state.loading = false;
		// },
		// [editCategory.pending.type]: (state) => {
		// 	state.loading = true;
		// },
	},
});

export default categories.reducer;
