import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagsCreate, TagsDelete, TagsEdit, TagsGetAll, TagsGetByTagCategoryId } from './TagsApi'; // Assuming you have a type like ApiResponse
import { AxiosResponse } from 'axios';
import { TagIDs } from '../../API';

export interface CategoryState<T> {
  items: T[];
  loading: boolean;
  current: T | null;
  subCategoryTag:any,
  methodTag:[],
  variationsTag:[],
  TagWithCategory:[],
  openAIpromentTag:[]

}

export interface ICategory {
  pageNo: number;
  PAGE_SIZE: number;
  token: string;
  body: any;
  CategoryId: any;
  value: any;
  _id: any;
}

const initialState: CategoryState<ICategory> = {
  items: [],
  subCategoryTag:[],
  methodTag:[],
  TagWithCategory:[],
  variationsTag:[],
  openAIpromentTag:[],
  current: null,
  loading: false,
};


const AsyncFunctionThunk = (name: string, apiFunction: any) => {
  return createAsyncThunk<any, any>(
      `Tags/${name}`,
      async (data: any, { rejectWithValue }) => {
          try {
              const response: AxiosResponse<any, any> = await apiFunction(data);
              return response.data;
          } catch (error: any) {
              console.error(`Failed to ${name}:`, error);
              if (error.response && error.response.data) {
                  return rejectWithValue(error.response.data);
              }
              return rejectWithValue({ error: error.message });
          }
      }
  );
};



export const TagsPost = AsyncFunctionThunk('TagsPost', TagsCreate);
export const TagsGet = AsyncFunctionThunk('TagsGet', TagsGetAll);
export const TagEdit = AsyncFunctionThunk('TagsEdit', TagsEdit);
export const TagDelete = AsyncFunctionThunk('TagsDelte', TagsDelete);
export const TagByTCId = AsyncFunctionThunk('TagsGetByTagCategoryId', TagsGetByTagCategoryId);
export const FilterTagByTCId = AsyncFunctionThunk('FilterTagByTCId', TagsGetByTagCategoryId);



 

 

 
  
const TagsSlice = createSlice({
  name: 'TagsSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TagsPost.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagsPost.fulfilled, (state, action: PayloadAction<any>) => {
        // state.items = action.payload;
        state.loading = false;
      })
      .addCase(TagsPost.rejected, (state) => {
        state.loading = false;
      })
      .addCase(TagsGet.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagsGet.fulfilled,(state, action: PayloadAction<any>)=>{
        state.items = action.payload?.items;
        state.loading = false;
      })
      .addCase(TagsGet.rejected, (state) => {
        state.loading = false;
      })
      .addCase(TagDelete.fulfilled, (state, action: PayloadAction<any>) => {
        // state.items = action.payload;
        state.loading = false;
      })
      .addCase(TagDelete.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagDelete.rejected, (state) => {
        state.loading = false;
      })
      .addCase(TagEdit.fulfilled,(state, action: PayloadAction<any>)=>{
        state.loading = false;
      })
      .addCase(TagEdit.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagEdit.rejected, (state) => {
        state.loading = false;
      })
      .addCase(TagByTCId.fulfilled,(state, action:any)=>{
        state.loading = false;
        if(action.payload[0] && action.payload[0]?.tagcategories_ids[0]?._id === TagIDs.subCategory){
           state.subCategoryTag = action.payload
        }
        else if (action.payload[0] && action.payload[0]?.tagcategories_ids[0]?._id === TagIDs.method){
          state.methodTag = action.payload
        }
        else if (action.payload[0] && action.payload[0]?.tagcategories_ids[0]?._id === TagIDs.variations){
          state.variationsTag = action.payload
        }
        else if (action.payload[0] && action.payload[0]?.tagcategories_ids[0]?._id === TagIDs.openAIproment){
          state.openAIpromentTag = action.payload
        }
        else{
          state.TagWithCategory = action.payload
        }
      })
      .addCase(TagByTCId.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(TagByTCId.rejected, (state) => {
        state.loading = false;
      })
      .addCase(FilterTagByTCId.fulfilled,(state, action:any)=>{
        state.loading = false;
          state.items = action.payload
      })
      .addCase(FilterTagByTCId.pending, (state: CategoryState<any>) => {
        state.loading = true;
      })
      .addCase(FilterTagByTCId.rejected, (state) => {
        state.loading = false;
      })
  },
});

export default TagsSlice.reducer;
