import { configureStore } from '@reduxjs/toolkit';
import BundleSlice from './bundles/bundle/BundleSlice';
import CategorySlice from './category/CategorySlice';
import TagsSlice from './Tags/TagsSlice';
import LeadSlice from './lead/LeadSlice';
import UserManagement_slice from './Slice/UserManagement_slice';
import TagsCatesSlice from './Tag&C/Tag&CSlice'

export const store = configureStore({
	reducer: {
		categories: CategorySlice,
		bundle: BundleSlice,
		tag: TagsSlice,
		Lead:LeadSlice,
		tagCate:TagsCatesSlice,
		user:UserManagement_slice
	},
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
