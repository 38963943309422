import axios from 'axios';
import { DoMeanderSoftware } from '../../API';

export const LeadsCreate = async (data:any) => {
    console.log('====================================');
    console.log('am running');
    console.log('====================================');
    const { values } = data;

    const options = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    try {
        const response = await axios.post(`${DoMeanderSoftware}outsourcingLeads/add`, values, options);
        return response.data;
    } catch (error) {
        console.error('Error creating lead:', error);
        throw error;
    }
};
