import React from 'react';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteBundle, editBundles, getBundles, postBundle,uploadProfilePicture,getBysearchBundles,getBundlesbyCategoryId,getByIdBundles, UpdateAdvanceDetail, UpdateMetaDetail, UpdateRefrencesDetail, getByFilterStatus, UpdateFieldsOpenAI } from './BundleApi';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
 

export interface BundleState<T> {
	items: T[];
	itemsID:T[];
	AdvanceDetails:[]
	error:any;
	loading: boolean;
	current: T | null;
}
export interface IBundle {
	pageNo: number;
	PAGE_SIZE: number;
	token: string;
	body: any;
	BundleId: any;
	value: any;
	_id: any;
}

const initialState: BundleState<IBundle> = {
	items: [],
	error:'',
	itemsID:[],
	AdvanceDetails:[],
	current: null,
	loading: false,
};

//* Get Bundle //
export const getBundlesbyCategory = createAsyncThunk(
	'Bundle/getBundlesbyCategoryId',
	async function (value:any) {
		
		
	 
		const response = await getBundlesbyCategoryId(value);

		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}   
	},
); 
//get all bundle //

export const getBundle = createAsyncThunk(
	'Bundle/getBundle',
	async function ({ pageNo, PAGE_SIZE }: IBundle) {
		
		
	 
		const response = await getBundles( pageNo, PAGE_SIZE);

		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}   
	},
); 
//get by id  bundle //

export const getBundlebyID = createAsyncThunk(
	'Bundle/getBundlebyID',
	async function (valueID:any) {
		
		
	 
		const response = await getByIdBundles(valueID);

		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}   
	},
);
               
export const getBysearchBundle = createAsyncThunk(
	'Bundle/getBysearchBundles',
	async function (value: any) {
		const response:any = await getBysearchBundles(value);
		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}
	},
);






export const getByfilterByStatus = createAsyncThunk(
	'Bundle/getByfilterByStatus',
	async function (value: any) {
		
		
	 
		const response:any = await getByFilterStatus(value);

		if (response.statusCode === 200) {
			return response.data;
		} else {
			throw response;
		}
	},
);

//* edit Bundle//
export const editBundle :any= createAsyncThunk('Bundle/editBundles', async function (body: any) {
	// const token = JSON.parse(localStorage.getItem('logintoken') as any);
	const {values,selectedImage,id}=body
	const response = await editBundles(values,id);
	if (response.statusCode === 200) {
		if(selectedImage){
			const Id = response?.data?._id
		uploadProfilePicture(selectedImage,Id)
		}
		return response.data;
	} else {
	 
			setLoading()
		throw response;   
	}
});

export const UpdateAdvanceDetails = createAsyncThunk('Bundle/UpdateAdvanceDetail', async function (body: any) {
	// const token = JSON.parse(localStorage.getItem('logintoken') as any);
	const {id}=body
	const response = await UpdateAdvanceDetail(id);
    return response.data;
})

export const UpdateFieldsOpenA = createAsyncThunk(
	'Bundle/UpdateFieldsOpenAI',
	async (body: any, { rejectWithValue }) => {
	  try {
		const response = await UpdateFieldsOpenAI(body);
 
		if (response.statusCode === 200) {
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon='Info' size='lg' className='me-1' />
					<span> Bundle Advance Details updated Successfully</span>
				</span>,
				"The Bundle's details have been successfully updated.",
			);
		 
			return response.data;
		} else {
			showNotification(
				<span className='d-flex align-items-center'>
				  <Icon icon='Error' size='lg' className='me-1' />
				  <span> Something went wrong. Please try again.</span>
				</span>,
				"Please try again"
				 
			  );
			throw response;
		}
 
	 
	  } catch (error:any) {
		return rejectWithValue(error.response ? error.response.data : error.message);
	  }
	}
  );


export const UpdateMetaDetails = createAsyncThunk('Bundle/UpdateMetaDetail', async function (body: any) {
	// const token = JSON.parse(localStorage.getItem('logintoken') as any);
	const {id}=body
	const response = await UpdateMetaDetail(id);
    return response.data;
})

export const UpdateRefrencesDetails = createAsyncThunk('Bundle/UpdateRefrencesDetail', async function (body: any) {
	// const token = JSON.parse(localStorage.getItem('logintoken') as any);
	const {id}=body
	const response = await UpdateRefrencesDetail(id);
    return response.data;
})
//* Add Bundle//

export const postBundles = createAsyncThunk('Bundle/postBundle', async function (body:any) {
	// const data1 = JSON.parse(localStorage.getItem("login"));
	// const token = data1.data.token;
	const {values,selectedImage}=body
	const response = await postBundle(values);

	if (response.statusCode === 200) {
		const Id = response?.data?._id
		uploadProfilePicture(selectedImage,Id)
		return response.data;
	} else {
		throw response;
	}
});

// Delete Bundle//
export const deleteBundles = createAsyncThunk('Bundle/deleteBundle', async function (body: any) {
	const token = JSON.parse(localStorage.getItem('logintoken') as any);

	const response = await deleteBundle(body, token);
	if (response.statusCode === 200) {
		return response.data;
	} else {
		throw response;
	}
});

const bundle = createSlice({
	name: 'bundle',
	initialState: initialState,
	reducers: {
		setLoading: (state) => {
		  state.loading = !state.loading;
		},

		errorNotifacion:()=>{
			
		}
	  },
	extraReducers: (builder) => {
		builder
		  // getBundle cases
		  .addCase(getBundle.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(getBundle.fulfilled, (state, action) => {
			state.items = action.payload;
		  })
		  .addCase(getBundle.rejected, (state) => {
			state.loading = false;
		  })
	
		  // getBundlebyID cases
		  .addCase(getBundlebyID.pending, (state) => {
			state.itemsID = [];
			state.loading = true;
		  })
		  .addCase(getBundlebyID.fulfilled, (state, action) => {
			state.itemsID = action.payload;
			state.loading = false;
		  })
		  .addCase(getBundlebyID.rejected, (state) => {
			state.loading = false;
		  })
	
		  // getBundlesbyCategory cases
		  .addCase(getBundlesbyCategory.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(getBundlesbyCategory.fulfilled, (state, action) => {
			state.items = action.payload;
			state.loading = false;
		  })
		  .addCase(getBundlesbyCategory.rejected, (state) => {
			state.loading = false;
		  })
	
		  // getBysearchBundle cases
		  .addCase(getBysearchBundle.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(getBysearchBundle.fulfilled, (state, action) => {
			state.items = action.payload;
			state.loading = false;
		  })
		  .addCase(getBysearchBundle.rejected, (state) => {
			state.loading = false;
		  })
	
		  // getByfilterByStatus cases
		  .addCase(getByfilterByStatus.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(getByfilterByStatus.fulfilled, (state, action) => {
			state.items = action.payload;
			state.loading = false;
		  })
		  .addCase(getByfilterByStatus.rejected, (state) => {
			state.loading = false;
		  })
	
		  // postBundles cases
		  .addCase(postBundles.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(postBundles.fulfilled, (state, action) => {
			state.items.push(action.payload);
			state.loading = false;
		  })
		  .addCase(postBundles.rejected, (state) => {
			state.loading = false;
		  })
	
		  // deleteBundles cases
		  .addCase(deleteBundles.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(deleteBundles.fulfilled, (state, action) => {
			state.items = state.items.filter((item) => item._id !== action.payload);
			state.loading = false;
		  })
		  .addCase(deleteBundles.rejected, (state) => {
			state.loading = false;
		  })
	
		  // editBundle cases
		  .addCase(editBundle.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(editBundle.fulfilled, (state) => {
			state.loading = false;
		  })
		  .addCase(editBundle.rejected, (state) => {
			state.loading = false;
		  })
	
		  // UpdateFieldsOpenA cases
		  .addCase(UpdateFieldsOpenA.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(UpdateFieldsOpenA.fulfilled, (state, action) => {
			state.itemsID = { ...state.itemsID, ...action.payload[0] };
			state.loading = false;
		  })
		  .addCase(UpdateFieldsOpenA.rejected, (state, action) => {
			console.log('====================================');
			console.log('asfghidsbdfs', action.payload || action.error.message);
			console.log('====================================');
			state.loading = false;
			state.error = action.payload || action.error.message;
		  })
	
		  // UpdateAdvanceDetails cases
		  .addCase(UpdateAdvanceDetails.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(UpdateAdvanceDetails.fulfilled, (state, action) => {
			state.itemsID = { ...state.itemsID, ...action.payload[0] };
			state.loading = false;
		  })
		  .addCase(UpdateAdvanceDetails.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload || action.error.message;
		  })
	
		  // UpdateMetaDetails cases
		  .addCase(UpdateMetaDetails.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(UpdateMetaDetails.fulfilled, (state, action) => {
			state.itemsID = { ...state.itemsID, ...action.payload[0] };
			state.loading = false;
		  })
		  .addCase(UpdateMetaDetails.rejected, (state) => {
			state.loading = false;
		  })
	
		  // UpdateRefrencesDetails cases
		  .addCase(UpdateRefrencesDetails.pending, (state) => {
			state.loading = true;
		  })
		  .addCase(UpdateRefrencesDetails.fulfilled, (state, action) => {
			state.itemsID = { ...state.itemsID, ...action.payload[0] };
			state.loading = false;
		  })
		  .addCase(UpdateRefrencesDetails.rejected, (state) => {
			state.loading = false;
		  });
	  },
});
export const { setLoading,errorNotifacion } = bundle.actions;
export default bundle.reducer;
