import React from 'react';
import { createRoot } from 'react-dom/client'; // For React 18
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.scss';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './contexts/themeContext';
import { AuthContextProvider } from './contexts/authContext';
import './i18n';
import { Provider } from 'react-redux';
import { store } from './store/Store';

// Wrap the app with all necessary providers
const children = (
  <AuthContextProvider>
    <ThemeContextProvider>
      <Router>
        <React.StrictMode>
          <Provider store={store}>
            <App />
          </Provider>
        </React.StrictMode>
      </Router>
    </ThemeContextProvider>
  </AuthContextProvider>
);

// Get the container element for mounting the app
const container = document.getElementById('root');

// Render the app using React 18's createRoot
if (container) {
  createRoot(container).render(children); // For React 18
}

// Measure performance (optional)
reportWebVitals();
