import React, { useLayoutEffect, forwardRef, ReactElement, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ISubHeaderProps } from '../SubHeader/SubHeader';
import { IPageProps } from '../Page/Page';
import { useNavigate, useParams } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import { demoPagesMenu } from '../../menu';
import { useLocation } from 'react-use';

interface IPageWrapperProps {
	isProtected?: boolean;
	title?: string;
	Sales?: string;
	children:
		| ReactElement<ISubHeaderProps>[]
		| ReactElement<IPageProps>
		| ReactElement<IPageProps>[];
	className?: string;
	
}
const PageWrapper = forwardRef<HTMLDivElement, IPageWrapperProps>(
	({ isProtected, title, Sales, className, children }, ref) => {
		useLayoutEffect(() => {
			// @ts-ignore
			document.getElementsByTagName('TITLE')[0].text = `${title ? `${title} | ` : ''}${
				process.env.REACT_APP_SITE_NAME
			}`;
			// @ts-ignore
			document
				?.querySelector('meta[name="Sales"]')
				.setAttribute('content', Sales || process.env.REACT_APP_META_DESC || '');
		});

		const { user,loginData,role } = useContext(AuthContext);
 
		const navigate = useNavigate();
		const location = useLocation();

		const state = location?.state?.usr as { fromPage: string, path: string };

		console.log('statestate', location)

		// useEffect

		// useEffect(() => {
		// 	console.log(loginData?.data?.roles);
			
		// 	if ( loginData && loginData?.data?.roles !== 'admin') {
		// 		navigate(`/`);
		// 	}
	 
 
		// }, [ ]);
		useEffect(() => {
			console.log(role,'dddddd');
			if(state?.fromPage === 'bundle'){
              navigate(state.path)
			}else{
				if (isProtected && role !== 'admin') {
					navigate(`/`);
				}
			}
			return () => {};
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
		return (
			<div ref={ref} className={classNames('page-wrapper', 'container-fluid', className)}>
				{children}
			</div>
		);
	},
);
PageWrapper.displayName = 'PageWrapper';
PageWrapper.propTypes = {
	isProtected: PropTypes.bool,
	title: PropTypes.string,
	Sales: PropTypes.string,
	// @ts-ignore
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
};
PageWrapper.defaultProps = {
	isProtected: true,
	title: undefined,
	Sales: undefined,
	className: undefined,
};

export default PageWrapper;
